import { useEffect, useRef, useState } from 'react';

/** Resolve PlyrControls rendering and progress-bar */
const useCheckPlyrControls = () => {
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const [plyrVideoKey, setPlyrVideoKey] = useState(1);

  useEffect(() => {
    const checkVideoControls = setTimeout(() => {
      if (!videoWrapperRef.current) return;

      const controls = videoWrapperRef.current?.querySelector('.plyr__controls');

      if (controls) return;

      setPlyrVideoKey((current) => current + 1);
    }, 4000);

    return () => clearTimeout(checkVideoControls);
  }, []);

  return { plyrVideoKey, videoWrapperRef };
};

export default useCheckPlyrControls;
