import { useMemo } from 'react';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import Box from '@mui/material/Box';
import { GalleryItemProps } from '@organisms/GalleryParagraph/GalleryItem/GalleryItem.props';
import useCheckPlyrControls from './useCheckPlyrControls';

const PlyrVideo = dynamic(() => import('@molecules/PlyrVideo/PlyrVideo'));

const GalleryItem = ({ index, handleImageClick, slidesNotInView, image, videoUrl, variant }: GalleryItemProps) => {
  const isItemInView = useMemo(() => slidesNotInView.includes(index), [index, slidesNotInView]);

  const { videoWrapperRef, plyrVideoKey } = useCheckPlyrControls();

  const imageBoxHeight =
    variant === 'vertical'
      ? { xs: theme.spacing(300), sm: theme.spacing(500), lg: theme.spacing(700) }
      : { xs: theme.spacing(147), lg: theme.spacing(426) };

  return (
    <Box
      sx={{
        transition: 'opacity 200ms ease',
        opacity: isItemInView ? 0.2 : 1,
      }}
    >
      {videoUrl && (
        <Box
          ref={videoWrapperRef}
          overflow="hidden"
          borderRadius={theme.borderRadius.card}
          height={{ xs: theme.spacing(178), lg: theme.spacing(569) }}
        >
          <PlyrVideo key={plyrVideoKey} videoUrl={videoUrl} />
        </Box>
      )}

      {image && (
        <Box
          borderRadius={theme.borderRadius.card}
          position="relative"
          overflow="hidden"
          bgcolor="common.black"
          height={imageBoxHeight}
        >
          <Box component="button" sx={{ cursor: 'pointer' }} onClick={() => handleImageClick(index)}>
            <Image style={{ objectFit: 'contain' }} fill src={image.imageUrl} alt={image.imageAlt} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default GalleryItem;
